<template>
  <div>
  <section class="wrapper bg-light">
  <div class="container pt-7 pb-1 pt-md-8">
    <div class="row gx-lg-0 gy-10 align-items-center">
      <!-- /column -->
      <div class="col-lg-6 pe-5   ">
        <h2 class="fs-15 text-uppercase text-muted mb-3">{{accroche}}</h2>
        <h3 class="display-4 mb-5">{{titre}}</h3>
        <p class="lead" v-html="lead" />
        <p class="lead" v-html="text" />
      <figure class="rounded mb-6 mb-md-0"><img :src="img0"   alt=""></figure>
      </div>
      <!-- /column -->
      <div class="col-lg-6 pe-5">
        <div class="row g-6 text-center">
          <div class="col-md-6">
            <div class="row">
              <div class="col-lg-12">
                <figure class="rounded mb-6"><img :src="img1"  alt=""></figure>
              </div>
              <!-- /column -->
              <div class="col-lg-12">
                <figure class="rounded mb-6 mb-md-0"><img :src="img2"   alt=""></figure>
                <!--/.card -->
              </div>
              <!-- /column -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /column -->
          <div class="col-md-6">
            <div class="row">
              <div class="col-lg-12 order-md-2">
                <figure class="rounded mb-6 mb-md-0"><img :src="img3"   alt=""></figure>
              </div>
              <!-- /column -->
              <div class="col-lg-12">
                <div class="card mb-md-6 mt-lg-6">
                  <figure class="rounded mb-6 mb-md-0"><img :src="img4"  alt=""></figure>
                  <!--/.card-body -->
                </div>
                <!--/.card -->
              </div>
              <!-- /column -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /column -->
        </div>
        <!-- /.row -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container -->
</section>

</div>
</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});


export default {
  name: 'feature2',
  components: {},
  props: {accroche:String,
  titre:String,
  img0:String,
img1:String,
  t1:String,
  p1:String,
  k1:String,
  l1:String,
img2:String,
  t2:String,
  p2:String,
  k2:String,
  l2:String,
img3:String,
  t3:String,
  p3:String,
  k3:String,
  l3:String,
img4:String,
  t4:String,
  p4:String,
  k4:String,
  l4:String,
  text:String,
  lead:String,
  color:String,},
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
