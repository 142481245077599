<template>
<div>
  <section class="wrapper bg-light">
    <div class="container py-7 py-md-8">
      <div class="row">
        <div class="col-lg-12 col-xl-12 col-xxl-12">
          <h2 class="fs-15 text-uppercase text-muted mb-3">{{accroche}}</h2>
          <h3 class="display-4 mb-9">{{titre}} </h3>

          <p class="lead" v-html="lead"></p>

          <p v-html="text" />
        </div>
        <!-- /column -->
      </div>
      <!-- /.row -->
      <div class="row gx-md-8 gy-8 mt-3">
        <div class="col-md-6 col-lg-4"  v-for="(item, index) in elements" :key="index">
          <div :class="'icon btn btn-block btn-lg   disabled mb-5 '+btncolor ">
            <img :src="item.image" />
          </div>
          <h4 v-html="item.title"></h4>
          <p class="mb-3" v-html="item.text" />
          <a v-if="item.link!=''" :href="k1" class="more hover link-yellow">{{item.link_label}}</a>
        </div>


        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</div>
</template>


<script>
export default {
  name: 'feature1',
  components: {},
  props: {
    accroche: String,
    titre: String,
    elements: [],
    text: String,
    lead: String,
    color: String,
  },
  data: () => ({

    btncolor: "btn-soft-yellow",

  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {
    if (this.color != undefined) this.btncolor = "btn-" + this.color;

  },
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
