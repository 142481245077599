<template>
<section class="wrapper bg-light">
  <div class="container py-14 py-md-16">
    <div class="row">
      <div class="col-lg-9 col-xl-8 col-xxl-7 mx-auto mb-8">
        <h2 class="fs-15 text-uppercase text-muted text-center mb-3" v-html="accroche"></h2>
        <h3 class="display-4 text-center" v-html="titre"></h3>
      </div>
      <!-- /column -->
    </div>
    <!-- /.row -->
    <div class="grid grid-view ">

      <div class="row gx-md-8 gy-10 gy-md-13  ">

        <!-- /.project -->

        <!-- /.project -->
        <div class="project item col-6   col-md-3 col-xl-2 product" style="height:100% " v-for="(item, index) in elements">
          <figure class="lift rounded mb-6 bloc" :style="'background-image: url('+item.image+')'">
            <a href="item.link content">

            </a>
          </figure>
          <div class="project-details d-flex justify-content-center flex-column">
            <div class="post-header">
              <div class="post-category text-line mb-3 text-yellow">{{item.category}}</div>
              <h2 class="post-title h3">{{item.title}}</h2>
              <p v-html="item.text">
              </p>
            </div>
            <!-- /.post-header -->
          </div>
          <!-- /.project-details -->
        </div>
        <!-- /.project -->

        <!-- /.project -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.grid -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
  name: 'portfolio1',
  components: {


  },
  props: {
elements:[],
    titre: String,
    accroche: String
  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style>
.bloc {
  position: relative;
   background-size: cover;
  width: 100%;
}

.bloc::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.bloc .content {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
