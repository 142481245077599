<template>
  <section class="wrapper bg-light">
    <div class="container py-7 py-md-8">
      <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
        <div class="col-lg-7">
          <div class="row gx-md-5 gy-5">
            <div class="col-md-6 col-xl-5 align-self-end">
              <div class="card bg-pale-yellow">
                <div class="card-body">
                  <blockquote class="icon mb-0">
                    <p>“Une petite part d'un bien immobilier transformé en actif financier”</p>
                    <div class="blockquote-details">
                      <div class="info p-0">
                        <h5 class="mb-1">Token</h5>

                      </div>
                    </div>
                  </blockquote>
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
            <div class="col-md-6 align-self-end">
              <div class="card bg-pale-red">
                <div class="card-body">
                  <blockquote class="icon mb-0">
                    <p>“Contrat qui s'execute automatiquement dont les termes sont tous connus à la signature et qui ne peut être modifié.”</p>
                    <div class="blockquote-details">
                      <div class="info p-0">
                        <h5 class="mb-1">Smart contract</h5>

                      </div>
                    </div>
                  </blockquote>
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
            <div class="col-md-6 col-xl-5 offset-xl-1">
              <div class="card bg-pale-leaf">
                <div class="card-body">
                  <blockquote class="icon mb-0">
                    <p>“Portefeuille d'actif numériques qui contient vos tokens et vos smart-contracts qui peut se connecter à une place de marché”</p>
                    <div class="blockquote-details">
                      <div class="info p-0">
                        <h5 class="mb-1">Wallet</h5>

                      </div>
                    </div>
                  </blockquote>
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
            <div class="col-md-6 align-self-start">
              <div class="card bg-pale-blue">
                <div class="card-body">
                  <blockquote class="icon mb-0">
                    <p>“Place de marché où s'achetent et se vendent les tokens sans intermédiaire centralisateur ”</p>
                    <div class="blockquote-details">
                      <div class="info p-0">
                        <h5 class="mb-1">Exchange</h5>

                      </div>
                    </div>
                  </blockquote>
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
          </div>
          <!--/.row -->
        </div>
        <!--/column -->
        <div class="col-lg-5">
          <h2 class="display-4 mb-3">Le nouveau vocabulaire immobilier </h2>
          <p class="lead fs-lg">Le meilleur de l'immobilier, de la finance et de la technologie.</p>
          <p>L'immobilier fractionné est une révolution technologique basée sur la tokenisation des actifs dans une bockchain.
          L'immobilier devient alors un actif financier décentralisé, fractionnable et transférable  en quelques clics. </p>
          <a href="Questions" class="btn btn-navy mt-3">Définitions et questions fréquentes</a>
        </div>
        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</template>


<script>


export default {
  name: 'testimonial2',
  components: {},
  props: {},
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
