<template>
<section class="wrapper bg-light">
  <div class="container py-14 py-md-16">
    <div class="row gy-6">
      <div class="col-lg-4">
        <h2 class="display-4 mt-lg-18 mb-3" v-html="titre" />
        <p class="lead fs-lg" v-html="lead" />
        <p v-html="text" />
        <a v-if="link_label1 != ''" href="link1" class="btn btn-primary rounded-pill mt-2" v-html ="link_label1" />
      </div>
      <!--/column -->
      <div class="col-lg-7 offset-lg-1 pricing-wrapper">

        <div class="row gy-6 position-relative mt-5">
          <div class="shape bg-dot primary rellax w-16 h-18" style="bottom: -0.5rem; right: -1.6rem;"></div>
          <div class="shape rounded-circle bg-line red rellax w-18 h-18" style="top: -1rem; left: -2rem;"></div>

          <div class="col-md-6" v-for="(item, index) in offres" :key="index">
            <div class="pricing card">
              <div class="card-body pb-12">
                <div class="prices text-dark">
                  <div class="price price-show">
                    <span class="price-currency">€</span>
                    <span class="price-value" v-html="item.prix" />
                    <span class="price-duration">mois</span>
                  </div>

                </div>
                <!--/.prices -->
                <h4 class="card-title mt-2" v-html="item.titre" />
                <ul class="icon-list bullet-bg bullet-soft-primary mt-8 mb-9">
                  <li v-for="(puce, index2) in item.puces" :key="'p'+index2">
                    <i :class="'uil uil-'+puce.icon+'  bullet-soft-'+puce.couleur"></i><span v-html="puce.text" />  </li>
              </ul>
                <a v-if="item.label !=''" href="item.link" class="btn btn-primary rounded-pill" v-html="item.label" />
              </div>
              <!--/.card-body -->
            </div>
            <!--/.pricing -->
          </div>


          <!--/column -->
        </div>
        <!--/.row -->
      </div>
      <!--/column -->
    </div>
    <!--/.row -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
  name: 'price2',
  components: {},
  props: {
    titre:String,
    lead:String,
    link_label1:String,
    link1:String,
    text:String,
    offres:[]
  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
