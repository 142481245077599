<template>
<div>
  <section class="wrapper bg-light">
    <div class="container py-7 py-md-8">
      <div class="row">
        <div class="col-lg-12 col-xl-12 col-xxl-12">
          <h2 class="fs-15 text-uppercase text-muted mb-3">{{accroche}}</h2>
          <h3 class="display-4 mb-9">{{titre}} </h3>

           <p class="lead" v-html="lead"></p>

            <p v-html="text" />
        </div>
        <!-- /column -->
      </div>
      <!-- /.row -->
      <div class="row gx-md-8 gy-8">
        <div class="col-md-6 col-lg-3">
          <div :class="'icon btn btn-block btn-lg   disabled mb-5 '+btncolor "> <i :class="'uil '+img1"></i> </div>
          <h4>{{t1}}</h4>
          <p class="mb-3"  v-html="p1" />
          <a v-if="l1!=''"  :href="k1" class="more hover link-yellow">{{l1}}</a>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3">
          <div :class="'icon btn btn-block btn-lg   disabled mb-5 '+btncolor ">  <i :class="'uil '+img2"></i> </div>
          <h4>{{t2}}</h4>
          <p class="mb-3"   v-html="p2" />
          <a v-if="l2!=''"  :href="k2" class="more hover link-yellow">{{l2}}</a>
        </div>
        <div class="col-md-6 col-lg-3">
          <div :class="'icon btn btn-block btn-lg   disabled mb-5 '+btncolor ">  <i :class="'uil '+img3"></i> </div>
          <h4>{{t3}}</h4>
          <p class="mb-3"  v-html="p3" />
          <a  v-if="l3!=''" :href="k3" class="more hover link-yellow">{{l3}}</a>
        </div>
        <div class="col-md-6 col-lg-3">
          <div :class="'icon btn btn-block btn-lg   disabled mb-5 '+btncolor "> <i :class="'uil '+img4"></i> </div>
          <h4>{{t4}}</h4>
          <p class="mb-3" v-html="p4" />
          <a v-if="l4!=''" :href="k4" class="more hover link-yellow">{{l4}}</a>
        </div>


        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</div>
</template>


<script>


export default {
  name: 'feature1',
  components: {},
  props: {
  accroche:String,
  titre:String,
img1:String,
  t1:String,
  p1:String,
  k1:String,
  l1:String,
img2:String,
  t2:String,
  p2:String,
  k2:String,
  l2:String,
img3:String,
  t3:String,
  p3:String,
  k3:String,
  l3:String,
img4:String,
  t4:String,
  p4:String,
  k4:String,
  l4:String,
  text:String,
  lead:String,
  color:String,
},
  data: () => ({

 btncolor : "btn-soft-yellow"
  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {
    if(this.color !=undefined) this.btncolor = "btn-"+this.color;

  },
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {  }
}
</script>

<style scoped>
</style>
