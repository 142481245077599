<template>
  <section class="wrapper bg-light">
    <div class="container py-7 py-md-8">
      <div class="row mb-5">
        <div class="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">

          <h2 class="display-4   text-red">{{titre}}</h2>
        </div>
        <!-- /column -->
      </div>
      <!-- /.row -->
      <div class="row gx-md-8 gx-xl-12 gy-10 py-10 py-md-12 ">
        <div class="col-lg-6 order-lg-2">
          <h2 class="fs-15 text-uppercase text-line text-primary mb-3">{{soustitre}}</h2>
          <div class="card me-lg-6">
            <div class="card-body p-6">
              <div class="d-flex flex-row">
                <div>
                  <span class="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span class="number">01</span></span>
                </div>
                <div>
                  <h4 class="mb-1">{{t1}}</h4>
                  <p class="mb-0">{{tx1}}</p>
                </div>
              </div>
            </div>
            <!--/.card-body -->
          </div>
          <!--/.card -->
          <div class="card ms-lg-13 mt-6">
            <div class="card-body p-6">
              <div class="d-flex flex-row">
                <div>
                  <span class="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span class="number">02</span></span>
                </div>
                <div>
                  <h4 class="mb-1">{{t2}}</h4>
                  <p class="mb-0">{{tx2}}</p>
                </div>
              </div>
            </div>
            <!--/.card-body -->
          </div>
          <!--/.card -->
          <div class="card mx-lg-6 mt-6">
            <div class="card-body p-6">
              <div class="d-flex flex-row">
                <div>
                  <span class="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span class="number">03</span></span>
                </div>
                <div>
                  <h4 class="mb-1">{{t3}}</h4>
                  <p class="mb-0">{{tx3}}</p>
                </div>
              </div>
            </div>
            <!--/.card-body -->
          </div>
          <!--/.card -->
        </div>
        <!--/column -->
        <div class="col-lg-6">




                    <h2 class="fs-15 text-uppercase text-line text-primary mb-3">{{soustitre2}}</h2>
                          <h3 class="display-5 mb-7 pe-xxl-5">{{lead2}}</h3>
                          <div class="d-flex flex-row mb-4">
                            <div>
                              <img src="@/assets/img/icons/house.svg" class="svg-inject icon-svg icon-svg-sm text-primary me-4" alt="" />
                            </div>
                            <div>
                              <h4 class="mb-1">{{it1}}</h4>
                              <p class="mb-1">{{id1}}</p>
                            </div>
                          </div>
                          <div class="d-flex flex-row mb-4">
                            <div>
                              <img src="@/assets/img/icons/pie-chart-2.svg" class="svg-inject icon-svg icon-svg-sm text-green me-4" alt="" />
                            </div>
                            <div>
                              <h4 class="mb-1">{{it2}}</h4>
                              <p class="mb-1">{{id2}}</p>
                            </div>
                          </div>
                          <div class="d-flex flex-row">
                            <div>
                              <img src="@/assets/img/icons/design.svg" class="svg-inject icon-svg icon-svg-sm text-yellow me-4" alt="" />
                            </div>
                            <div>
                              <h4 class="mb-1">{{it3}}</h4>
                              <p class="mb-0">{{id3}}</p>
                            </div>
                          </div>


        </div>
        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</template>


<script>


export default {
  name: 'process2',
  components: {},
  props: {

    t1: String,
    t2: String,
    t3: String,


    tx1: String,
    tx2: String,
    tx3: String,
titre: String,
soustitre: String,
text: Array,
 lead: String,
lead2: String,
 it1: String,
 id1: String,
 it3: String,
 id3: String,
 it2: String,
 id2: String,
soustitre2: String,
  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
