<template>
<section class="wrapper bg-light">
  <div class="container pt-9   pb-14 pb-lg-6">
    <div class="row align-items-center">

      <h1 class="display-3  mb-10">{{titre}}</h1>

      <div class="d-lg-none">
        <div class="contenant">



          <div class="texte1_top1.no my-3" v-html="'<h4>'+top1+'</h4>'+top1t"></div>
          <div class="texte1_top2.no my-3" v-html="'<h4>'+top2+'</h4>'+top2t"></div>
          <div class="texte1_top3.no my-3" v-html="'<h4>'+top3+'</h4>'+top3t"></div>
          <div class="texte1_bottom1.no my-3" v-html="'<h4>'+bottom1+'</h4>'+bottom1t"></div>
          <div class="texte1_bottom2.no my-3" v-html="'<h4>'+bottom2+'</h4>'+bottom2t"></div>
          <div class="texte1_bottom3.no my-3" v-html="'<h4>'+bottom3+'</h4>'+bottom3t"></div>
          <hr class="my-1" />
          <div class="texte1_centrer.no my-3" v-html="center"></div>
        </div>
      </div>

      <div class=" 	d-none d-lg-block d-xl-none   ">
        <div class="contenant">
          <img src="https://media.berlcoin.fr/orga-2.png">
          <div class="texte2_centrer" v-html="center"></div>
          <div class="texte2_top1" v-html="'<h4>'+top1+'</h4>'+top1t"></div>
          <div class="texte2_top2" v-html="'<h4>'+top2+'</h4>'+top2t"></div>
          <div class="texte2_top3" v-html="'<h4>'+top3+'</h4>'+top3t"></div>
          <div class="texte2_bottom1" v-html="'<h4>'+bottom1+'</h4>'+bottom1t"></div>
          <div class="texte2_bottom2" v-html="'<h4>'+bottom2+'</h4>'+bottom2t"></div>
          <div class="texte2_bottom3" v-html="'<h4>'+bottom3+'</h4>'+bottom3t"></div>
        </div>
      </div>

      <div class=" 	d-none d-xxl-block d-xl-block   ">
        <div class="contenant">
          <img src="https://media.berlcoin.fr/orga-3.png">
          <div class="texte3_centrer" v-html="center"></div>
          <div class="texte3_top1" v-html="'<h4>'+top1+'</h4>'+top1t"></div>
          <div class="texte3_top2" v-html="'<h4>'+top2+'</h4>'+top2t"></div>
          <div class="texte3_top3" v-html="'<h4>'+top3+'</h4>'+top3t"></div>
          <div class="texte3_bottom1" v-html="'<h4>'+bottom1+'</h4>'+bottom1t"></div>
          <div class="texte3_bottom2" v-html="'<h4>'+bottom2+'</h4>'+bottom2t"></div>
          <div class="texte3_bottom3" v-html="'<h4>'+bottom3+'</h4>'+bottom3t"></div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>




<script>
export default
{
  name: 'organigram-1',
  components:
  {},
  props:
  {
    titre: String,
    top1: String,
    top2: String,
    top3: String,

    top1t: String,
    top2t: String,
    top3t: String,

    bottom1: String,
    bottom2: String,
    bottom3: String,

    bottom1t: String,
    bottom2t: String,
    bottom3t: String,

    center: String,

  },
  data: () => (
  {}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed:
  {},
  methods:
  {},
  watch:
  {}
}
</script>

<style>
.contenant
{
  position: relative;
}

.texte3_centrer
{
  position: absolute;
  text-align: center;
  top: 330px;
  left: 350px;
  width: 490px;
  height: 180px;
}

.texte3_top1
{
  position: absolute;
  top: 100px;
  left: 115px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte3_top2
{
  position: absolute;
  text-align: left;
  top: 30px;
  left: 505px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte3_top3
{
  position: absolute;
  text-align: left;
  top: 110px;
  left: 905px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte3_bottom1
{
  position: absolute;
  text-align: left;
  top: 520px;
  left: 115px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte3_bottom2
{
  position: absolute;
  text-align: left;
  top: 600px;
  left: 505px;
  width: 250px;
  height: 150px;
  font-size: 90%;

}

.texte3_bottom3
{
  position: absolute;
  text-align: left;
  top: 520px;
  left: 905px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}


.texte2_centrer
{
  position: absolute;
  text-align: center;
  top: 450px;
  left: 220px;
  width: 490px;
  height: 180px;
}

.texte2_top1
{
  position: absolute;
  top: 230px;
  left: 115px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte2_top2
{
  position: absolute;
  text-align: left;
  top: 30px;
  left: 380px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte2_top3
{
  position: absolute;
  text-align: left;
  top: 230px;
  left: 660px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte2_bottom1
{
  position: absolute;
  text-align: left;
  top: 660px;
  left: 115px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte2_bottom2
{
  position: absolute;
  text-align: left;
  top: 890px;
  left: 380px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte2_bottom3
{
  position: absolute;
  text-align: left;
  top: 660px;
  left: 660px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}


.texte1_centrer
{
  position: absolute;
  text-align: center;
  top: 630px;
  left: 100px;
  width: 290px;
  height: 180px;
}

.texte1_top1
{
  position: absolute;
  top: 50px;
  left: 160px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte1_top2
{
  position: absolute;
  text-align: left;
  top: 240px;
  left: 160px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte1_top3
{
  position: absolute;
  text-align: left;
  top: 440px;
  left: 160px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte1_bottom1
{
  position: absolute;
  text-align: left;
  top: 800px;
  left: 160px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte1_bottom2
{
  position: absolute;
  text-align: left;
  top: 1030px;
  left: 160px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}

.texte1_bottom3
{
  position: absolute;
  text-align: left;
  top: 1250px;
  left: 160px;
  width: 250px;
  height: 150px;
  font-size: 90%;
}
</style>

<style scoped>
</style>
