<template>
  <section class="wrapper bg-light">
    <div class="container py-5 py-md-6">
      <h2 class="display-4 mb-3">{{titre}}</h2>
      <p class="lead fs-lg mb-8">{{lead}}</p>
      <div class="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line">
        <div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">01</span></span>
          <h4 class="mb-1">{{it1}}</h4>
          <p class="mb-0">{{id1}}</p>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">02</span></span>
          <h4 class="mb-1">{{it2}}</h4>
          <p class="mb-0">{{id2}}</p>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">03</span></span>
          <h4 class="mb-1">{{it3}}</h4>
          <p class="mb-0">{{id3}}</p>
        </div>
        <!--/column -->
        <div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">04</span></span>
          <h4 class="mb-1">{{it4}}</h4>
          <p class="mb-0">{{id4}}</p>
        </div>

      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</template>


<script>


export default {
  name: 'process5',
  components: {},
  props: {
    titre: String,
  lead: String,
   it1: String,
   id1: String,
   it3: String,
   id3: String,
   it2: String,
   id2: String,
   it4: String,
   id4: String,

 },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
